import React, { useState } from 'react';
import style from './WatchCard.module.scss';
import threeDots from '../../assets/images/three_dots.svg';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from '..';
import { Watch } from '../../models/models.type';
import axios from 'axios';
import { useAuthContext } from '../../context/authContext';

type Props = {
  watch: Watch;
  lastSync: string;
  onSubmitDelete: (uuid: string) => void;
  onSubmitEdit: (newWatch: Watch) => void;
};

const WatchCard = ({ watch, lastSync, onSubmitDelete, onSubmitEdit }: Props) => {
  const { state } = useAuthContext();
  const [isEditModalActive, setIsEditModalActive] = useState(false);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [canDeleteWatch, setCanDeleteWatch] = useState(false);
  const [newWatch, setNewWatch] = useState<Watch>(watch);
  const [deleteError, setDeleteError] = useState<string | undefined>();
  const [editError, setEditError] = useState<string | undefined>();

  let history = useHistory();
  const { uuid, name, internalId } = watch;
  const handleClick = () => {
    history.push(`/dashboard/${uuid}`);
  };

  const handleOpenEditModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditModalActive(true);
  };
  const handleCloseEditModal = () => {
    setIsEditModalActive(false);
  };

  const handleOpenDeleteModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeleteModalActive(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalActive(false);
  };

  const handleWatchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'deleteInput')
      e.target.value === internalId.toString() ? setCanDeleteWatch(true) : setCanDeleteWatch(false);
    else setNewWatch((prevWatch) => ({ ...prevWatch, name: e.target.value }));
  };

  const handleSubmitDeleteWatch = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/watches/${uuid}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then(() => {
        onSubmitDelete(uuid);
      })
      .catch(() => {
        setDeleteError('Impossible de supprimer la montre');
      });
  };

  const handleSubmitEditWatch = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/watches/${uuid}`,
        {
          name: newWatch.name,
        },
        {
          headers: { Authorization: `Bearer ${state.token}` },
        }
      )
      .then(() => {
        onSubmitEdit(newWatch);
        handleCloseEditModal();
      })
      .catch(() => {
        setEditError('Impossible de modifier la montre');
      });
  };

  return (
    <div className={style.card} onClick={handleClick}>
      <p className={style.watchInfo}>{internalId}</p>
      <p className={style.watchInfo}>{name}</p>
      <p className={style.watchInfo}>{lastSync}</p>
      <div className={style.popper}>
        <img alt="three dots" src={threeDots} className={style.optionBtn} />
        <div className={style.popperContent}>
          <button className={style.popperBtn} onClick={(e) => handleOpenEditModal(e)}>
            Modifier
          </button>
          <button className={style.popperBtn} onClick={(e) => handleOpenDeleteModal(e)}>
            Supprimer
          </button>
        </div>
      </div>
      {isEditModalActive && (
        <Modal onClose={handleCloseEditModal} title={`Modifier la montre "${name}"`}>
          <div className={style.modalBody}>
            <div className={style.inputGroup}>
              <label>Nom de la montre</label>
              <input
                type="text"
                value={newWatch.name}
                name="edit"
                onChange={handleWatchInputChange}
              />
              {editError && <p className={style.error}>{editError}</p>}
            </div>
            <Button onClick={handleSubmitEditWatch}>Valider</Button>
            <button className={style.cancelBtn} onClick={handleCloseEditModal}>
              Annuler
            </button>
          </div>
        </Modal>
      )}
      {isDeleteModalActive && (
        <Modal onClose={handleCloseDeleteModal} title={`Supprimer la montre ${name}`}>
          <div className={style.modalBody}>
            <p>Pour confirmer la suppression de cette montre, vous devez saisir son ID: </p>
            <div className={style.inputGroup}>
              <label>ID de la montre</label>
              <input
                type="text"
                name="deleteInput"
                onChange={handleWatchInputChange}
                placeholder={`${internalId}`}
              />
              {deleteError && <p className={style.error}>{deleteError}</p>}
            </div>
            <Button disabled={!canDeleteWatch} onClick={handleSubmitDeleteWatch}>
              Valider
            </Button>
            <button className={style.cancelBtn} onClick={handleCloseDeleteModal}>
              Annuler
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default WatchCard;
