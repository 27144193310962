import React from 'react';
import './Card.scss';

type Props = {
  children: React.ReactNode;
  title?: string;
  className?: string;
};

const Card: React.FC<Props> = (({ title, className, children }) => {
  return (
    <div className={`card ${className}`}>
      {title && <h3>{title}</h3>}
      {children}
    </div>
  );
});

export default Card;
