import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import dayJs from 'dayjs';
import { useAuthContext } from '../../context/authContext';
import { Card } from '../';
import { AnnotationsType } from '../../models/models.type';
import style from './Annotations.module.scss';
import Spinner from '../Spinner/Spinner';

type Props = {
  uuid: string;
};

const Annotations = ({ uuid }: Props) => {
  const { state, dispatch } = useAuthContext();
  const [day, setDay] = useState<dayJs.Dayjs>(dayJs());
  const [mod, setMod] = useState(false);
  const [annotations, setAnnotations] = useState<AnnotationsType[]>([]);
  const [annotation, setAnnotation] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const temp = annotations.find((currDay: AnnotationsType) =>
      dayJs(currDay.date).isSame(day, 'day')
    );
    if (temp) setAnnotation(temp.content);
    else setAnnotation('');
  }, [day, annotations]);
  useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_API_URL}/watches/${uuid}/annotations`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        setAnnotations(
          res.data.data.edges.map((annotation: { node: AnnotationsType }) => ({
            uuid: annotation.node.uuid,
            content: annotation.node.content,
            date: annotation.node.date,
          }))
        );
      })
      .catch((error) => {
        if (error.response.status === 401) dispatch({ type: 'LOGOUT' });
      })
      .finally(() => setLoading(false));
  }, [state.token, uuid, dispatch]);

  const handleSave = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/watches/${uuid}/annotations`,
        {
          date: day.format('YYYY-MM-DD'),
          content: annotation,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(({ data }) => {
        setAnnotations((prevState) => [
          ...prevState.filter(
            (currDay: AnnotationsType) => !dayJs(currDay.date).isSame(day, 'day')
          ),
          {
            uuid: data.data.uuid,
            date: data.data.date,
            content: data.data.content,
          },
        ]);
      })
      .catch((error) => {
        if (error.response.status === 401) dispatch({ type: 'LOGOUT' });
      });
    setMod(false);
  };

  const handleDelete = () => {
    const uuidToDelete = annotations.find((annotation) =>
      dayJs(annotation.date).isSame(day, 'day')
    )?.uuid;
    axios
      .delete(`${process.env.REACT_APP_API_URL}/annotations/${uuidToDelete}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then(() =>
        setAnnotations(annotations.filter((annotation) => annotation.uuid !== uuidToDelete))
      )
      .catch((error) => {
        if (error.response.status === 401) dispatch({ type: 'LOGOUT' });
      });
    setMod(false);
  };

  const handleCancel = () => {
    const temp = annotations.find((currDay: AnnotationsType) =>
      dayJs(currDay.date).isSame(day, 'day')
    );
    temp ? setAnnotation(temp.content) : setAnnotation('');
    setMod(!mod);
  };

  const handleDayChange = (newDate: Date | Date[]): void => {
    if (!Array.isArray(newDate)) setDay(dayJs(newDate));
  };

  return (
    <Card title="Annotations">
      {loading && <Spinner />}
      {!loading && (
        <div className={style.container}>
          <Calendar
            className={style.calendar}
            value={day.toDate()}
            onChange={handleDayChange}
            locale="fr-FR"
            tileContent={({ date }) => {
              const currDay = dayJs(date);
              if (annotations.find((tempDay) => currDay.isSame(tempDay.date, 'day')))
                return <div className={style.singleDay} />;
              return null;
            }}
          />
          <div className={style.annotation}>
            <div className={style.bodyHeader}>
              <h4>Annotations associées au jour de saisie</h4>
              {!mod ? (
                <button className={style.modBtn} onClick={() => setMod(!mod)}>
                  Modifier
                </button>
              ) : (
                <button className={style.delBtn} onClick={handleDelete}>
                  Supprimer
                </button>
              )}
            </div>
            <textarea
              value={annotation}
              disabled={!mod}
              placeholder="Écrire ici ..."
              onChange={(e) => setAnnotation(e.target.value)}
              className={style.annotationTextArea}
            />
            {mod && (
              <div className={style.bodyFooter}>
                <button className={style.saveBtn} onClick={handleSave}>
                  Sauvegarder
                </button>
                <button className={style.modBtn} onClick={handleCancel}>
                  Annuler
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default Annotations;
