import { Chart } from 'react-chartjs-2';

type tooltipContext = {
	// The chart the tooltip is being shown on
	chart: typeof Chart;
	// Label for the tooltip
	label: string;
	// Parsed data values for the given `dataIndex` and `datasetIndex`
	parsed: { x: number; y: number };
	// Raw data values for the given `dataIndex` and `datasetIndex`
	raw: object;
	// Formatted value for the tooltip
	formattedValue: string;
	// The dataset the item comes from
	dataset: { type: string; label: string };
	// Index of the dataset the item comes from
	datasetIndex: number;
	// Index of this data item in the dataset
	dataIndex: number;
	// The chart element (point, arc, bar, etc.) for this tooltip item
	element: Element;
};

export const optionsGraph1 = {
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
			position: 'bottom',
			labels: {
				usePointStyle: true,
			},
		},
		tooltip: {
			backgroundColor: 'rgba(255,255,255,0.8)',
			bodyColor: 'black',
			titleColor: 'black',
			borderWidth: 1,
			borderColor: '#ececec',
			multiKeyBackground: 'rgba(255,255,255,0.8)',
			usePointStyle: true,
			callbacks: {
				label: (context: tooltipContext) => {
					let label = context.dataset.label || '';
					if (label) {
						label += ': ';
					}
					if (context.parsed.y !== null) {
						if (context.dataset.type === 'bar') label += `${context.parsed.y.toFixed(2)}%`;
						else {
							label += context.parsed.y;
						}
					}
					return label;
				},
				title: (context: tooltipContext[]) => {
					const ISODate = context[0].label.split('/').reverse().join('-');
					const dateString = new Date(ISODate).toLocaleString('fr-FR', {
						weekday: 'long',
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					});
					return dateString.charAt(0).toUpperCase() + dateString.slice(1);
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				callback: function (_: void, idx: number) {
					return !idx ? 'J' : 'J + ' + idx;
				},
			},
		},
		eventsNumber: {
			display: true,
			id: 'eventsNumber',
			type: 'linear',
			position: 'left',
			title: {
				display: true,
				text: "Nombre d'événements",
				font: {
					size: 14,
				},
			},
			grid: {
				display: true,
			},
			suggestedMin: 0,
			suggestedMax: 2,
			ticks: {
				min: 0,
				stepSize: 1,
			},
		},
		coverPercentage: {
			display: true,
			id: 'coverPercentage',
			type: 'linear',
			position: 'right',
			title: {
				display: true,
				text: 'Taux de couverture (%)',
				font: {
					size: 14,
				},
			},
			grid: {
				display: false,
			},
			suggestedMin: 0,
			suggestedMax: 100,
			ticks: {
				max: 100,
				min: 0,
				stepSize: 10,
			},
		},
	},
};

export const optionsGraph2 = {
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
			position: 'bottom',
			labels: {
				usePointStyle: true,
			},
		},
		tooltip: {
			backgroundColor: 'rgba(255,255,255,0.8)',
			bodyColor: 'black',
			titleColor: 'black',
			borderWidth: 1,
			borderColor: '#ececec',
			multiKeyBackground: 'rgba(255,255,255,0.8)',
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
		},
		y: {
			display: true,
			type: 'linear',
			position: 'left',
			grid: {
				display: true,
			},
			suggestedMin: 0,
			suggestedMax: 2,
			ticks: {
				min: 0,
				stepSize: 1,
			},
		},
	},
};
