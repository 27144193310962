import React from 'react';
import { Spinner } from '..';
import { ResponsiveHeatMapCanvas } from '@nivo/heatmap';
import { HeatmapData } from '../../models/models.type';

type Props = {
	data?: HeatmapData[];
	indexBy?: string;
	colors?: string;
	isLegend?: boolean;
	maxValue: number;
};

const dataKeys = [
	'00h',
	'01h',
	'02h',
	'03h',
	'04h',
	'05h',
	'06h',
	'07h',
	'08h',
	'09h',
	'10h',
	'11h',
	'12h',
	'13h',
	'14h',
	'15h',
	'16h',
	'17h',
	'18h',
	'19h',
	'20h',
	'21h',
	'22h',
	'23h',
];

const blues = ['#c7c7c7', '#EBEFF4', '#D1D9E6', '#97A7C3', '#5F79A5', '#193566'];
const oranges = ['#c7c7c7', '#FFE6D5', '#FCCCA9', '#FFB27B', '#FF974D', '#EC690A'];

export const Heatmap: React.FC<Props> = ({
	data,
	indexBy = 'day',
	colors,
	isLegend = false,
	maxValue,
}) => {
	const margins = { top: isLegend ? 20 : 40, right: 0, bottom: isLegend ? 0 : 50, left: 40 };
	const paddings = isLegend ? 2 : 0;
	const legendData = {
		data: [
			{
				day: 'day',
				NA: '-10',
				'0': 0,
				[Math.round(maxValue / 3)]: Math.round(maxValue / 3),
				[Math.round((maxValue * 2) / 3)]: Math.round((maxValue * 2) / 3),
				[maxValue]: maxValue,
			},
		],
		keys: [
			'NA',
			'0',
			`${Math.round(maxValue / 3)}`,
			`${Math.round((maxValue * 2) / 3)}`,
			`${maxValue}`,
		],
	};
	return !isLegend && !data ? (
		<Spinner />
	) : (
		<ResponsiveHeatMapCanvas
			data={!isLegend && data ? data : legendData?.data}
			keys={!isLegend ? dataKeys : legendData?.keys}
			enableLabels={false}
			axisTop={isLegend ? {} : null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			padding={paddings}
			indexBy={indexBy}
			colors={colors === 'oranges' ? oranges : blues}
			isInteractive={!isLegend}
			hoverTarget="cell"
			maxValue={maxValue}
			minValue={-1}
			margin={margins}
			cellBorderColor="white"
			cellBorderWidth={1}
			cellHoverOthersOpacity={1}
			tooltip={({ xKey, yKey, value }) => (
				<span>
					{yKey} - {xKey}: <strong>{value < 0 ? 'NA' : value}</strong>
				</span>
			)}
		/>
	);
};

export default Heatmap;
