import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { User } from '../models/models.type';

const SET_LOGIN = 'SET_LOGIN';
const GET_LOGIN = 'GET_LOGIN';
const LOGOUT = 'LOGOUT';

type Action = {
	type: typeof SET_LOGIN | typeof GET_LOGIN | typeof LOGOUT;
	payload?: User;
};

type ContextProps = {
	state: User;
	dispatch: React.Dispatch<Action>;
};

const initialUser: User = {
	firstname: '',
	lastname: '',
	login: '',
	uuid: '',
	token: '',
};

const reducer = (state: User, action: Action) => {
	switch (action.type) {
		case SET_LOGIN: {
			if (action.payload) {
				localStorage.setItem('access_token', JSON.stringify(action.payload));
				return action.payload;
			}
			return initialUser;
		}
		case GET_LOGIN: {
			const user = localStorage.getItem('access_token');
			if (!user) return initialUser;
			return JSON.parse(user);
		}
		case LOGOUT: {
			localStorage.clear();
			return initialUser;
		}
		default:
			return state;
	}
};

const AuthContext = createContext<ContextProps>({} as ContextProps);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
	const [state, dispatch] = useReducer(reducer, initialUser);

	useEffect(() => {
		dispatch({ type: GET_LOGIN });
	}, [dispatch]);

	return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};
