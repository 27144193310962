import React, { useRef, useState, useEffect } from 'react';
import styles from './AddDataModal.module.scss';
import doc from '../../assets/images/doc.svg';
import { Button, Modal } from '..';
import axios from 'axios';
import { useAuthContext } from '../../context/authContext';
import Spinner from '../Spinner/Spinner';

export type responseError = {
	data: {
		data: {
			errors: {
				edges: {
					0: {
						node: {
							code: number;
							data: {
								parameterName: string;
							};
							message: string;
						};
					};
				};
			};
		};
	};
};

const errorCodes: any = {
	'1': 'Erreur du serveur',
	'2': 'Veuillez vous reconnecter',
	'3': 'Veuillez vous reconnecter',
	'4': 'Paramètre manquant',
	'5': 'Paramètre invalide',
	'6': 'Sensors incconu',
	'7': '',
	'8': 'Montre inconnue',
	'9': "Vous n'avez pas les droits",
};

type Props = {
	onClose: (state: 'idle' | 'loading' | 'error' | 'success') => void;
	watchUUID: string;
};

type fileError = {
	name: string;
	error: string;
};

const AddDataModal = ({ onClose, watchUUID }: Props) => {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [invalidFiles, setInvalidFiles] = useState<fileError[]>([]);
	const [error, setError] = useState<string | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { state } = useAuthContext();

	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault();
	};

	const handleDragEnter = (e: React.DragEvent) => {
		e.preventDefault();
	};

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault();
	};

	const checkFileValidity = (file: File) => {
		if (file.type !== 'application/json') return { isValid: false, reason: 'Fichier invalide' };
		if (selectedFiles.some((selectedFile) => selectedFile.name === file.name))
			return { isValid: false, reason: 'Fichier déjà chargé' };
		return { isValid: true, reason: null };
	};

	const handleFiles = (files: FileList) => {
		[...files].forEach((file) => {
			const { isValid, reason } = checkFileValidity(file);
			if (!isValid && reason) {
				setInvalidFiles((prevArray) => [
					...prevArray,
					{
						name: file.name,
						error: reason,
					},
				]);
				return;
			}
			setSelectedFiles((prevArray) => [...prevArray, file]);
		});
	};

	const fileDrop = (e: React.DragEvent) => {
		e.preventDefault();
		setInvalidFiles([]);
		const files = e.dataTransfer.files;
		if (files.length) {
			handleFiles(files);
		}
	};

	const inputFilesSelected = () => {
		if (fileInputRef?.current?.files?.length) {
			handleFiles(fileInputRef.current.files);
		}
	};

	const removeFile = (name: string) => {
		const validFileIndex = selectedFiles.filter((file) => file.name !== name);
		setSelectedFiles(validFileIndex);
		const InvalidFilesIndex = invalidFiles.filter((file) => file.name !== name);
		setInvalidFiles(InvalidFilesIndex);
	};

	const fileInputClicked = () => {
		fileInputRef.current?.click();
	};

	const handleResponseErrors = (data: responseError) => {
		const err = data.data.data.errors.edges[0].node;
		let translatedErr = errorCodes[`${err.code}`];
		err.code === 5 &&
			(translatedErr = `${translatedErr} (${
				err.data.parameterName.includes('named') && 'mauvais nom de fichier'
			}): ${err.data.parameterName}`);
		return translatedErr;
	};

	const handleSubmit = () => {
		setError(undefined);
		setIsLoading(true);
		let form = new FormData();
		for (const file of selectedFiles) {
			form.append('watchFiles', file, file.name);
		}
		axios
			.post(`${process.env.REACT_APP_API_URL}/watches/${watchUUID}/sensors`, form, {
				headers: { Authorization: `Bearer ${state.token}` },
			})
			.then(() => {
				onClose('success');
			})
			.catch((res) => {
				setError(handleResponseErrors(res.response));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		selectedFiles.length % 2 !== 0
			? setError('Vous devez envoyer vos fichiers par paire (sensors & tags)')
			: setError(undefined);
	}, [selectedFiles]);

	const reqState = (error && 'error') || (isLoading && 'loading') || 'idle';

	return (
		<Modal title="Ajouter des données" onClose={() => onClose(reqState)}>
			<div>
				{invalidFiles.map((file, index) => (
					<div className={styles.inputErrorMessage} key={file.name + index}>
						<p>{file.name}</p>
						<p>{file.error}</p>
					</div>
				))}
				{error && (
					<div className={styles.errorMessage}>
						<p>{error}</p>
					</div>
				)}
				{!isLoading ? (
					<>
						<div
							className={styles.container}
							onDragOver={handleDragOver}
							onDragEnter={handleDragEnter}
							onDragLeave={handleDragLeave}
							onDrop={fileDrop}
						>
							<img className={styles.uploadIcon} alt="upload" src={doc} />
							<p className={styles.dropMsg}>Glissez vos fichiers ici pour les envoyer</p>
						</div>
						<p className={styles.openExplorerText}>
							ou en{' '}
							<span onClick={fileInputClicked} className={styles.openExplorerBtn}>
								parcourant vos fichiers.
							</span>
						</p>
					</>
				) : (
					<Spinner />
				)}
				<input
					ref={fileInputRef}
					className={styles.fileInput}
					type="file"
					multiple
					onChange={inputFilesSelected}
					accept="application/json"
				/>
				<div className={styles.filesDisplay}>
					{selectedFiles.map((file) => (
						<div className={styles.fileStatus} key={`${file.name}-${file.size}`}>
							<p className={`${styles.fileName} ${styles.fileInfo}`}>{`${file.name}`}</p>
							<button className={styles.removeFile} onClick={() => removeFile(file.name)}>
								&times;
							</button>
						</div>
					))}
				</div>
				<div>
					<Button
						disabled={!selectedFiles.length || isLoading || selectedFiles.length % 2 !== 0}
						onClick={handleSubmit}
					>
						Envoyer
					</Button>
					<button className={styles.cancelBtn} onClick={() => onClose('idle')}>
						Annuler
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default AddDataModal;
