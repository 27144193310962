import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import style from './WatchInfos.module.scss';
import smartWatch from '../../assets/images/smartwatch.svg';
import { AddDataModal, SmallSpinner } from '../';
import { Ranges } from '../../models/models.type';
import dlIcon from '../../assets/images/download-icon.svg';
import synchroWatch from '../../assets/images/synchro-watch.svg';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

type Props = {
	infos: any;
	ranges: Ranges;
	pdfContent: React.RefObject<HTMLDivElement>;
};
type ImportDataState = 'idle' | 'loading' | 'error' | 'success';
const WatchInfos = ({ infos, ranges, pdfContent }: Props) => {
	const [isAddDataModalOpen, setIsAddDataModalOpen] = useState(false);
	const [extractLoading, setExtractLoading] = useState(false);
	const [importDataState, setImportDataState] = useState<ImportDataState>('idle');

	const handleOpenModal = () => {
		setIsAddDataModalOpen(true);
	};

	const handleCloseModal = (state: ImportDataState) => {
		setIsAddDataModalOpen(false);
		setImportDataState(state);
	};
	useEffect(() => {
		if (importDataState === 'success') setTimeout(() => setImportDataState('idle'), 5000);
	}, [importDataState]);

	const handleConversion = () => {
		setExtractLoading(true);
		const graph = pdfContent.current as HTMLElement;
		if (pdfContent.current) {
			graph.style.paddingTop = '32px';
			(
				pdfContent.current.getElementsByClassName('graphSection')[0] as HTMLDivElement
			).style.boxShadow = 'none';
			(
				pdfContent.current.getElementsByClassName(style.dataButtons)[0] as HTMLDivElement
			).style.display = 'none';
		}

		html2canvas(graph).then((canvas) => {
			const contentWidth = canvas.width;
			const contentHeight = canvas.height;
			const imgWidth = 595.28;
			const imgHeight = (592.28 / contentWidth) * contentHeight;

			let pageHeight = (contentWidth / 592.28) * 841.89;
			let leftHeight = contentHeight;
			let position = 0;

			const pageData = canvas.toDataURL('image/jpeg', 1.0);
			const pdf = new jsPDF(undefined, 'pt', 'a4');

			if (leftHeight < pageHeight) {
				pdf.addImage(pageData, 'JPEG', 5, 5, imgWidth, imgHeight);
			} else {
				while (leftHeight > 0) {
					pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
					leftHeight -= pageHeight;
					position -= 841.89;
					if (leftHeight > 0) {
						pdf.addPage();
					}
				}
			}
			pdf.save(
				`${infos.internalId}_${dayjs(ranges.range1[0]).format('DD-MM-YY')}/${dayjs(
					ranges.range1[1]
				).format('DD-MM-YY')}`
			);
			setExtractLoading(false);
		});
		if (pdfContent.current) {
			graph.style.paddingTop = '0px';
			(
				pdfContent.current.getElementsByClassName('graphSection')[0] as HTMLDivElement
			).style.boxShadow = '10px 3px 50px rgba(0, 0, 0, 0.16)';
			(
				pdfContent.current.getElementsByClassName(style.dataButtons)[0] as HTMLDivElement
			).style.display = 'flex';
		}
	};

	return (
		<div className={style.container}>
			{importDataState === 'success' && (
				<div className={style.importSuccessMsgContainer}>
					<p className={style.importSuccessMsg}>Données ajoutées avec succès</p>
				</div>
			)}
			<div className={style.infos}>
				<h4 className={style.watchId}>
					<img className={style.watchIcon} src={smartWatch} alt="smart-watch" />
					{infos.internalId}
				</h4>
				<h4 className={style.watchModel}>{infos.name}</h4>
			</div>
			<div className={style.dataButtons}>
				{importDataState === 'loading' && (
					<div className={style.importDataLoading}>
						Données en cours d'importation... <SmallSpinner />
					</div>
				)}
				<button id="addBtn" className={style.importButton} onClick={handleOpenModal}>
					<img src={synchroWatch} alt="synchroWatch" width={16} />
					Ajouter des données
				</button>
				<button id="exportBtn" className={style.exportButton} onClick={handleConversion}>
					<img src={dlIcon} alt="dl" width={16} />
					{extractLoading ? 'Chargement...' : 'Exporter'}
				</button>
			</div>
			{isAddDataModalOpen && <AddDataModal onClose={handleCloseModal} watchUUID={infos.uuid} />}
		</div>
	);
};

export default WatchInfos;
