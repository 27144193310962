import React, { useState, useEffect, useCallback } from 'react';
import dayJs from 'dayjs';
import Calendar, { ViewCallback } from 'react-calendar';
import { RangeDisplay } from '../';
import { Ranges, Hint, HintResponse } from '../../models/models.type';
import style from './RangePicker.module.scss';
import close from '../../assets/images/date_arrow.svg';
import dayjs from 'dayjs';
import axios from 'axios';
import { useAuthContext } from '../../context/authContext';

type Props = {
	ranges: Ranges;
	setRanges: React.Dispatch<React.SetStateAction<Ranges>>;
	watchId: string;
};
const ISO_DATE_FORMAT = 'DD/MM/YYYY';

const RangePicker: React.FC<Props> = ({ ranges, setRanges, watchId }): JSX.Element => {
	const { state, dispatch } = useAuthContext();
	const [open, setOpen] = useState(false);
	const [compare, setCompare] = useState(false);
	const [hints, setHints] = useState<Hint[]>([]);
	const [localRange, setLocalRange] = useState(ranges);
	const rangePickerRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		setCompare(!dayJs(ranges.range2[0]).isSame(ranges.range2[1], 'date'));
		setLocalRange(ranges);
	}, [open, ranges]);

	const handleChange = (e: any) => {
		!compare
			? setLocalRange((range) => ({ ...range, range1: e }))
			: setLocalRange((range) => ({ ...range, range2: e }));
	};

	const dateFormat = (range: Date[]) => {
		return dayJs(range[0]).isSame(range[1], 'date')
			? 'Aucune plage saisie'
			: `${dayJs(range[0]).format('DD/MM/YYYY')} - ${dayJs(range[1]).format('DD/MM/YYYY')}`;
	};

	const cancelRange = () => {
		setLocalRange({ range1: [new Date(), new Date()], range2: [new Date(), new Date()] });
		setCompare(false);
		setOpen(false);
	};

	const sendRange = () => {
		setRanges(localRange);
		setOpen(false);
	};

	const handleCompareCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		if (!checked)
			setLocalRange((prevRanges) => ({ ...prevRanges, range2: [new Date(), new Date()] }));
		setCompare(checked);
	};
	const handleRangeChange = (id: number) => (newRange: Date[]) => {
		setLocalRange((range) => ({ ...range, [`range${id}`]: newRange }));
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (rangePickerRef.current && !rangePickerRef.current.contains(event.target as Node)) {
			setOpen(false);
			document.removeEventListener('mousedown', handleClickOutside);
		}
	};
	const getHints = useCallback(async (date) => {
		const dateTime = new Date(date).getTime() + 86400000;
		const { data } = await axios.get<HintResponse>(
			`${process.env.REACT_APP_API_URL}/watches/${watchId}/coverage?date=${dateTime}`,
			{
				headers: { Authorization: `Bearer ${state.token}` },
			}
		);
		setHints(data.data.edges[0].node.values.map(({ x, y }) => ({ day: x, hasEvent: y })));
	}, []);
	const handleOpenRangePicker = () => {
		getHints(new Date());
		setOpen(true);
		document.addEventListener('mousedown', handleClickOutside);
	};

	const handleActiveDateChange: ViewCallback = async ({ activeStartDate, view, ...rest }) => {
		if (view !== 'month') return;
		getHints(activeStartDate);
	};

	return (
		<div className={style.picker}>
			<button className={style.pickerButton} onClick={handleOpenRangePicker}>
				<div className={style.dateDisplayContainer}>
					<div className={style.dateDisplay}>
						<time>{dateFormat(ranges.range1)}</time>
					</div>
					{(compare || !dayJs(ranges.range2[0]).isSame(ranges.range2[1], 'date')) && (
						<div className={style.dateDisplay}>
							<p>Comparer au:&nbsp;</p>
							<time>{dateFormat(ranges.range2)}</time>
						</div>
					)}
				</div>
				<img
					src={close}
					className={open ? style.openImage : ''}
					alt="close"
					height="10"
					width="10"
				/>
			</button>
			{open && (
				<div className={style.pickerContainer} ref={rangePickerRef}>
					<RangeDisplay range={localRange.range1} onRangeChange={handleRangeChange(1)} />
					<div className={style.compareButton}>
						<input type="checkbox" name="compare" checked={compare} onChange={handleCompareCheck} />
						<label htmlFor="compare" className={style.compareLabel}>
							Comparer
						</label>
					</div>
					{compare && (
						<RangeDisplay range={localRange.range2} onRangeChange={handleRangeChange(2)} />
					)}
					<Calendar
						className={style.rangeCalendar}
						locale="FR-fr"
						selectRange
						value={!compare ? localRange.range1 : localRange.range2}
						onChange={handleChange}
						minDetail="month"
						onActiveStartDateChange={handleActiveDateChange}
						tileContent={({ date: tileDate, view }) => {
							const isoDate = dayjs(tileDate).format(ISO_DATE_FORMAT);
							return view === 'month' && hints.some((d) => d.day === isoDate && d.hasEvent) ? (
								<div style={{ position: 'relative' }}>
									<div className={style.hint}></div>
								</div>
							) : null;
						}}
					/>
					<div className={style.dataButtons}>
						<button
							className={style.applyButton}
							disabled={dayJs(localRange.range1[0]).isSame(localRange.range1[1], 'date')}
							onClick={sendRange}
						>
							Appliquer
						</button>
						<button className={style.modBtn} onClick={cancelRange}>
							Annuler
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default RangePicker;
