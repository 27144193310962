import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import dayJs from 'dayjs';
import { Bar } from 'react-chartjs-2';
import { Card, Heatmap, RangePicker, NoRange } from '../';
import { useAuthContext } from '../../context/authContext';
import { Ranges, HeatmapData } from '../../models/models.type';
import style from './Events.module.scss';
import { optionsGraph1, optionsGraph2 } from './options';

const chartColors = {
	line1: 'rgba(0, 39, 87, 1)',
	line2: 'rgba(236, 105, 10, 1)',
	bar1: 'rgba(166, 206, 227, 1)',
	bar2: 'rgba(248, 226, 212, 1)',
};
const getEventEvolutionDataset = (rangeNumber: number, range: SensorData | undefined) => ({
	type: 'line',
	label: `Évènements (période ${rangeNumber})`,
	yAxisID: 'eventsNumber',
	borderColor: chartColors[`line${rangeNumber}` as keyof typeof chartColors],
	borderWidth: 2,
	fill: false,
	pointStyle: 'circle',
	radius: 2,
	hoverRadius: 6,
	backgroundColor: 'transparent',
	data: range && range.edges[0].node.values.map((item: { x: string; y: string }) => item.y),
});
const getCoverageDataset = (rangeNumber: number, range: SensorData | undefined) => ({
	type: 'bar',
	label: `Taux de couverture (période ${rangeNumber})`,
	pointStyle: 'rect',
	yAxisID: 'coverPercentage',
	backgroundColor: chartColors[`bar${rangeNumber}` as keyof typeof chartColors],
	borderWidth: 0,
	border: 'none',
	data: range && range.edges[1].node.values,
});
const getEventHourMean = (rangeNumber: number, range: SensorData | undefined) => ({
	type: 'bar',
	label: `Moyenne horaire (période ${rangeNumber})`,
	backgroundColor: chartColors[`line${rangeNumber}` as keyof typeof chartColors],
	data: range && range.edges[2].node.values,
	borderWidth: 0,
});
type SensorData = {
	edges: any[];
	count: number;
};
type Props = {
	uuid: string;
	ranges: Ranges;
	setRanges: React.Dispatch<React.SetStateAction<Ranges>>;
};

const formatHeatmapData = (heatmapData: HeatmapData[]): HeatmapData[][] => {
	const nbrDays = 7;
	const newHeatmapData = [];

	for (let i = 0; i < heatmapData.length; i += nbrDays) {
		newHeatmapData.push(heatmapData.slice(i, i + nbrDays));
	}

	const dayToFill = 7 - newHeatmapData[newHeatmapData.length - 1].length;
	for (let i = 0; i < dayToFill; i++) {
		newHeatmapData[newHeatmapData.length - 1].push({
			day: Array(i).fill(' ').join(' '),
			'00h': '-10',
			'01h': '-10',
			'02h': '-10',
			'03h': '-10',
			'04h': '-10',
			'05h': '-10',
			'06h': '-10',
			'07h': '-10',
			'08h': '-10',
			'09h': '-10',
			'10h': '-10',
			'11h': '-10',
			'12h': '-10',
			'13h': '-10',
			'14h': '-10',
			'15h': '-10',
			'16h': '-10',
			'17h': '-10',
			'18h': '-10',
			'19h': '-10',
			'20h': '-10',
			'21h': '-10',
			'22h': '-10',
			'23h': '-10',
		});
	}
	return newHeatmapData;
};

const Events = ({ uuid, ranges, setRanges }: Props) => {
	const { state, dispatch } = useAuthContext();
	const [compare, setCompare] = useState(false);
	const [kpisRange1, setKpisRange1] = useState<SensorData>();
	const [kpisRange2, setKpisRange2] = useState<SensorData>();
	const [display, setDisplay] = useState<boolean>(false);
	const getUTCTime = (date: Date) => date.getTime() - date.getTimezoneOffset() * 60000;

	useEffect(() => {
		axios
			.get<{ data: SensorData }>(
				`${process.env.REACT_APP_API_URL}/watches/${uuid}/kpis?from=${getUTCTime(
					ranges.range1[0]
				)}&to=${getUTCTime(ranges.range1[1])}`,
				{
					headers: { Authorization: `Bearer ${state.token}` },
				}
			)
			.then((res) => {
				res.data.data.edges[3].node.values = formatHeatmapData(res.data.data.edges[3].node.values);
				setKpisRange1(res.data.data);
			})
			.catch((error) => {
				if (error.response.status === 401) dispatch({ type: 'LOGOUT' });
			});

		if (compare) {
			axios
				.get<{ data: SensorData }>(
					`${process.env.REACT_APP_API_URL}/watches/${uuid}/kpis?from=${getUTCTime(
						ranges.range2[0]
					)}&to=${getUTCTime(ranges.range2[1])}`,
					{
						headers: { Authorization: `Bearer ${state.token}` },
					}
				)
				.then((res) => {
					res.data.data.edges[3].node.values = formatHeatmapData(
						res.data.data.edges[3].node.values
					);
					setKpisRange2(res.data.data);
				})
				.catch((error) => {
					if (error.response.status === 401) dispatch({ type: 'LOGOUT' });
				});
		}
		setCompare(!dayJs(ranges.range2[0]).isSame(ranges.range2[1], 'date'));
		setDisplay(hasRange(ranges.range1));
	}, [state, uuid, ranges, dispatch, compare]);

	const hasRange = (range: Date[]): boolean => {
		return !dayJs(range[0]).isSame(range[1], 'date');
	};

	const EventsEvolution = {
		labels:
			kpisRange1 && kpisRange1.edges[0].node.values.map((item: { x: string; y: string }) => item.x),
		datasets: [
			getEventEvolutionDataset(1, kpisRange1),
			compare ? getEventEvolutionDataset(2, kpisRange2) : {},
			getCoverageDataset(1, kpisRange1),
			compare ? getCoverageDataset(2, kpisRange2) : {},
		],
	};

	const EventHourMeans = {
		labels: [...Array(24)].map((_, hour) => (hour < 10 ? `0${hour}h` : `${hour}h`)),
		datasets: [getEventHourMean(1, kpisRange1), compare ? getEventHourMean(2, kpisRange2) : {}],
	};

	const formatLegend = (range: Date[], rangeId: number) => {
		return (
			<div className={style.legendText}>
				<div className={rangeId === 1 ? style.firstCircle : style.secondCircle} />
				Événements de la période du{' '}
				<span className={style.legendDate}>{dayJs(range[0]).format('DD/MM/YYYY')}</span> au{' '}
				<span className={style.legendDate}>{dayJs(range[1]).format('DD/MM/YYYY')}</span>
			</div>
		);
	};

	const findMaxTick = useMemo<number>(() => {
		return Math.max(kpisRange1?.edges[0].node.values) || 10;
	}, [kpisRange1]);

	const getMaxValue = (heatmapData: any[]) => {
		if (!heatmapData) return 0;
		const values = heatmapData.map((week: any) => {
			const dayValues: any[] = [];
			week.map((day: any) => {
				for (const key in day) {
					dayValues.push(day[key] > -10 ? +day[key] : 0);
				}
			});
			return dayValues;
		});
		return Math.max(...(values.flat() as number[]));
	};

	return (
		<Card className={`graphSection ${style.card}`}>
			<div className={style.container}>
				<div className={style.header}>
					<h3>Suivi des événements</h3>
					<RangePicker ranges={ranges} setRanges={setRanges} watchId={uuid} />
				</div>
				<div className={style.graph}>
					<h4>Évolution des événements</h4>
					<div className={style.canvas}>
						{display ? (
							<Bar data={EventsEvolution} type="bar" options={optionsGraph1} />
						) : (
							<>
								<Bar data={false} type="bar" options={optionsGraph1} />
								<NoRange />
							</>
						)}
					</div>
					<div className={style.legend}>
						{display && formatLegend(ranges.range1, 1)}
						{compare && formatLegend(ranges.range2, 2)}
					</div>
				</div>
				<div className={style.graph}>
					<h4>Moyennes horaires des événements</h4>
					<div className={style.canvas}>
						{display ? (
							<Bar type="bar" data={EventHourMeans} options={optionsGraph2} />
						) : (
							<>
								<Bar data={false} type="bar" options={optionsGraph2} />
								<NoRange />
							</>
						)}
					</div>
				</div>
				<div className={style.graph}>
					<h4>Répartition des événements</h4>
					<div className={style.heatmapContainer}>
						{!display && <NoRange />}
						{display && !hasRange(ranges.range2) && (
							<div className={style.heatmap}>
								<div className={style.heatmapLegend}>
									<Heatmap isLegend maxValue={getMaxValue(kpisRange1?.edges[3].node.values)} />
								</div>
								{kpisRange1?.edges[3].node.values.map((heatmapData: HeatmapData[], idx: number) => (
									<div key={idx} className={style.heatmapGraph}>
										<Heatmap
											maxValue={getMaxValue(kpisRange1?.edges[3].node.values)}
											data={heatmapData}
										/>
									</div>
								))}
							</div>
						)}
						{display && hasRange(ranges.range2) && (
							<>
								<div className={style.heatmap}>
									<div className={style.heatmapLegend}>
										<Heatmap isLegend maxValue={getMaxValue(kpisRange1?.edges[3].node.values)} />
									</div>
									{kpisRange1?.edges[3].node.values.map(
										(heatmapData: HeatmapData[], idx: number) => (
											<div key={idx} className={style.heatmapGraph}>
												<Heatmap
													data={heatmapData}
													maxValue={getMaxValue(kpisRange1?.edges[3].node.values)}
												/>
											</div>
										)
									)}
								</div>
								<div className={style.heatmap}>
									<div className={style.heatmapLegend}>
										<Heatmap
											colors="oranges"
											isLegend
											maxValue={getMaxValue(kpisRange2?.edges[3].node.values)}
										/>
									</div>
									{kpisRange2?.edges[3].node.values.map(
										(heatmapData: HeatmapData[], idx: number) => (
											<div key={idx} className={style.heatmapGraph}>
												<Heatmap
													colors="oranges"
													data={heatmapData}
													maxValue={getMaxValue(kpisRange2?.edges[3].node.values)}
												/>
											</div>
										)
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</Card>
	);
};

export default Events;
