import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from '../../context/authContext';

const ProtectedRoute = ({ ...props }): JSX.Element => {
	const [loading, setLoading] = useState(true);
	const { state } = useAuthContext();

	useEffect(() => {
		setLoading(false);
	}, []);

	return loading ? <div /> : state?.token ? <Route {...props} /> : <Redirect to="/login" />;
};

export default ProtectedRoute;
