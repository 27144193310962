import React from 'react';
import './Button.scss';

type Props = {
	disabled?: boolean;
	onClick?: () => void;
};

const Button: React.FC<Props> = ({ disabled = false, onClick, children }) => {
	return (
		<button
			className={`button ${disabled ? 'disabled' : ''}`}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default Button;
