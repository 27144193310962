import React from 'react';
import dayJs from 'dayjs';
import style from './RangeDisplay.module.scss';

type Props = {
  range: Date[];
  onRangeChange: (newRange: Date[]) => void;
};

const RangeDisplay: React.FC<Props> = ({ range, onRangeChange }): JSX.Element => {
  const display = !dayJs(range[0]).isSame(range[1], 'date');

  const handleDateChange = (e: React.FocusEvent<HTMLInputElement>, dateId: number) => {
    if (e.target.value) {
      const newRange = !dateId
        ? [new Date(e.target.value), range[1]]
        : [range[0], new Date(e.target.value)];
      onRangeChange(newRange);
    }
  };

  const dateInput = (date: Date, dateId: number) => {
    return (
      <div className={style.dateWrapper}>
        <input
          type="date"
          defaultValue={display ? dayJs(date).format('YYYY-MM-DD') : undefined}
          onBlur={(e) => handleDateChange(e, dateId)}
        />
      </div>
    );
  };

  return (
    <div className={style.container}>
      <div>
        <p>Date de début</p>
        {dateInput(range[0], 0)}
      </div>
      <div>
        <p>Date de fin</p>
        {dateInput(range[1], 1)}
      </div>
    </div>
  );
};

export default RangeDisplay;
