import React from 'react';
import { useAuthContext } from '../../context/authContext';

import logo from '../../assets/images/logo.svg';
import style from './PageLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

const PageLayout: React.FC<Props> = ({ children }): JSX.Element => {
  const { dispatch } = useAuthContext();

  return (
    <div className={style.container}>
      <main className={style.content}>
        <header className={style.header}>
          <img src={logo} width={104} height={33} alt="movidoc" />
          <button className={style.deconnectionBtn} onClick={() => dispatch({ type: 'LOGOUT' })}>
            Se déconnecter
          </button>
        </header>
        <section>{children}</section>
      </main>
    </div>
  );
};

export default PageLayout;
