import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ProtectedRoute, PageLayout, Footer } from './components';
import { AuthProvider } from './context/authContext';

const Login = lazy(() => import('./pages/Login/Login'));
const Register = lazy(() => import('./pages/Register/Register'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const WatchesList = lazy(() => import('./pages/WatchesList/WatchesList'));

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<Suspense fallback={<></>}>
					<Switch>
						<Route path="/login" component={Login} />
						<Route path="/register" component={Register} />
						<PageLayout>
							<Switch>
								<ProtectedRoute path="/" exact>
									<WatchesList />
								</ProtectedRoute>
								<ProtectedRoute path="/dashboard/:uuid" exact>
									<Dashboard />
								</ProtectedRoute>
								<Route path="/">
									<Redirect to="/login" />
								</Route>
							</Switch>
						</PageLayout>
					</Switch>
					<Footer />
				</Suspense>
			</AuthProvider>
		</BrowserRouter>
	);
};

export default App;
